<script>
  /* COMPONENT DOCUMENT
  * author: David.dai
  * date: 2020/07/16
  * desc: 开证申请详情
  */

  export default {
    name: 'applyDetail',

    props: {
      detailapply: {
        type: Number,
        default: 1
      },

      detailData: {
        type: Object,
        default() {
          return {};
        }
      }
    },

    data() {
      return {
        auditOpinion: 1, // 审核意见（1:通过 2:拒绝）
        reasonTextarea: '', // 审核拒绝原因
        showApply: localStorage.getItem('idLeaderNb')
      };
    },
    created() {
      console.log(this.detailapply, this.showApply);
      console.log(this.detailData, 'detailData222');
    },
    methods: {
      submitAudit() {
        if (this.auditOpinion === 2 && !this.reasonTextarea) {
          this.$message({ type: 'error', message: '请填写拒绝原因' });
          return;
        }
        // console.log();
        if (this.auditOpinion === 1) {
          this.$emit('applySave', {
            id: this.detailData.id,
            type: true,
            reason: this.reasonTextarea
          });
          // this.auditOpinion = true;
        } else {
          this.$emit('applySave', {
            id: this.detailData.id,
            type: false,
            reason: this.reasonTextarea
          });
        }

      }
    },
    watch: {
      detailapply: {
        handler(val) {
          this.detailapply = val;
        },
        deep: true
      },
      detailData: {
        handler(val) {
          this.detailapply = val;
        },
        deep: true
      }
    }
  };
</script>

<template>
  <div class='module-apply-detail'>
    <div class="detail-item">
      <div class="detail-item-title">
        开证信息
      </div>
      <div class="detail-item-info">
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">开证种类：</div>
          <div class="detail-item-info-row-cont">{{['','拆违相关','优惠相关'][detailData.credentialType]}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">开证用途：</div>
          <div class="detail-item-info-row-cont">{{detailData.purpose}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">开证数量：</div>
          <div class="detail-item-info-row-cont">{{detailData.quantity}}份</div>
        </div>
        <div class="detail-item-info-row" v-if="Array.isArray(detailData.imgList) && detailData.imgList.length > 0">
          <div class="detail-item-info-row-label">整改通知照片：</div>
          <div class="detail-item-info-row-cont">
            <a :href="itm" target="_blank" v-for="(itm, idx) in detailData.imgList" :key="idx"> <img :src="itm" alt=""
                                                                                                     style="width: 6rem;height: 6rem;"></a>

          </div>
        </div>

        <!--        <div class="detail-item-info-row">-->
        <!--          <div class="detail-item-info-row-label">整改通知照片：</div>-->
        <!--          <div class="detail-item-info-row-cont">-->
        <!--            <img src="http://nanfan-mall.kechuang.cn/public/upload/store/52/goods/9958b7222f51a7ea0bd60d91251eaf17.jpeg" alt="" style="width: 6rem;height: 6rem;">-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </div>
    <div class="detail-item">
      <div class="detail-item-title">
        单位信息
      </div>
      <div class="detail-item-info">
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">单位名称：</div>
          <div class="detail-item-info-row-cont">{{detailData.companyName}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">基地地址：</div>
          <div class="detail-item-info-row-cont">{{detailData.baseAddress}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">基地面积：</div>
          <div class="detail-item-info-row-cont">{{detailData.baseArea}}{{detailData.baseAreaUnit}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">单位负责人：</div>
          <div class="detail-item-info-row-cont">{{detailData.linkman}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">联系方式：</div>
          <div class="detail-item-info-row-cont">{{detailData.linkmanPhone}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">单位所属省份：</div>
          <div class="detail-item-info-row-cont">{{detailData.companyProvince}}</div>
        </div>
        <div class="detail-item-info-row">
          <div class="detail-item-info-row-label">提交时间：</div>
          <div class="detail-item-info-row-cont">{{detailData.createdDate}}</div>
        </div>
      </div>
    </div>
    <div class="detail-item">
      <div class="detail-item-title">
        审核信息
      </div>
      <div class="detail-item-info" v-if="detailData.credentialProcessList">
        <!--        123123-->
        <div class="detail-item-info-row" style="display: flex" v-for="(item, index) in detailData.credentialProcessList" :key="index">
          <div class="detail-item-info-row-label" style="margin-left: 2rem;">{{item.approvalLevel === 1?'一级审批：':'二级审批：'}}</div>
          <div class="detail-item-info-row-cont" style="width: 100%">
            {{item.approver}}
            <span :class="['tag  tag-blue','tag tag-yellow','tag tag-pass','tag tag-red'][item.approvalStatus]">
              {{['未开始','待审核','已通过','已驳回'][item.approvalStatus]}}
            </span>
            <span style="float: right">{{item.approvalDate}}</span>
          </div>
        </div>
        <!--123123-->
        <!--        <p></p>-->
        <!--        <div class="verify-apply-cont">-->
        <!--          <el-input-->
        <!--                  class="reason"-->
        <!--                  type="textarea"-->
        <!--                  v-model="detailData.auditInformation"-->
        <!--                  :autosize="{ minRows: 2, maxRows: 4}"-->
        <!--                  placeholder="在此输入驳回原因">-->
        <!--          </el-input>-->
        <!--        </div>-->
        <div class="verify-apply" v-if="detailapply === 2">
          <div class="verify-apply-label">审核意见：</div>
          <div class="verify-apply-cont">
            <el-radio-group v-model="auditOpinion">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">驳回</el-radio>
            </el-radio-group>
            <el-input
                    v-if="auditOpinion === 2"
                    class="reason"
                    type="textarea"
                    v-model="reasonTextarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="在此输入驳回原因">
            </el-input>
          </div>
        </div>

        <div class="btn-area" v-if="detailapply === 2">
          <el-button @click="submitAudit" type="primary">提交审核</el-button>
        </div>
      </div>
    </div>
    <div class="verify-apply-label" style="text-align: left;padding-left: 3rem;margin-top: -1.5rem;" v-if="detailData.status === 3">
      <span style="color: #999999;margin-right: 1.25rem">驳回理由：</span>{{detailData.auditInformation}}
    </div>

  </div>
</template>

<style lang='less' scoped>
  .module-apply-detail {
    padding: 10px 40px;

    .detail-item {
      text-align: left;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        color: #4B5162;
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
          width: 8px;
          height: 8px;
          background: rgba(52, 136, 254, 1);
          box-shadow: 1px 2px 3px 0px rgba(52, 136, 254, 0.7);
          border-radius: 50%;
        }
      }

      &-info {
        &-row {
          display: flex;
          margin-bottom: 15px;

          &-label {
            color: #999999;
            width: 120px;
            text-align: right;
            font-size: 16px;
            margin-right: 20px;
          }

          &-cont {
            color: #4B5162;
            font-size: 16px;

            img {
              max-width: 160px;
            }

            .tag {
              display: inline-block;
              font-size: 11px;
              color: #ffffff;
              padding: 2px 6px;
              border-radius: 12px 12px 12px 0;
              background: #41B873;
              margin-left: 10px;

              &.tag-pass {
                background: #41B873;
              }
              &.tag-blue {
                background: #2b6db8;
              }
              &.tag-yellow {
                background: #c5e168;
              }
              &.tag-red {
                background: #dc3827;
              }

              &.tag-await {
                background: #FD8F36;
              }

              &.tag-refuse {
                background: #ff3f3f;
              }
            }
          }
        }

        .verify-apply {
          display: flex;
          background: rgba(208, 213, 226, .3);
          border-radius: 6px;
          padding: 20px 30px;

          &-label {
            min-width: 90px;
            max-width: 90px;
            text-align: right;
            font-size: 16px;
            margin-right: 20px;
          }

          .reason {
            margin-top: 10px;
          }
        }
      }
    }

    .btn-area {
      padding: 20px 0 0 0;
      text-align: center;
    }
  }
</style>
